<template>
	<edit-template ref="edit" class="role-edit" @confirm="submitForm('ruleForm')" @cancel="cancel" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm" label-width="150rem">
        <el-form-item label="产品名称" prop="name">
          <el-select v-model="ruleForm.name" placeholder="请选择产品名称">
            <el-option v-for="item in nameArr" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本号" prop="code">
          <el-input v-model="ruleForm.code" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="sha1校验码" prop="app_sha1">
          <el-input v-model="ruleForm.app_sha1" placeholder="请输入sha1校验码"></el-input>
        </el-form-item>
        <el-form-item label="内部版本号" prop="edition">
          <el-input v-model="ruleForm.edition" placeholder="请输入内部版本号"></el-input>
        </el-form-item>
        <el-form-item label="是否强制升级" prop="force">
          <el-radio-group v-model="ruleForm.force">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否热更" prop="type">
          <el-radio-group v-model="ruleForm.type">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发布时间" required="">
          <el-form-item prop="release_at">
            <el-date-picker v-model="ruleForm.release_at" :popper-append-to-body="false" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" type="datetime"></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="更新内容" required="">
          <el-form-item prop="content">
            <el-input v-model="ruleForm.content" :rows="2" placeholder="请输入更新内容" style="width: 289.17rem" type="textarea"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="备注">
          <el-form-item>
            <el-input v-model="ruleForm.remarks" :rows="2" placeholder="请输入备注" style="width: 289.17rem" type="textarea"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="!ruleForm.type?'请上传apk':'请上传热更文件'" prop="apk_url">
          <el-upload :show-file-list="false" :http-request="uploadSectionFile" :action="action">
            <el-button :loading="loading" :disabled="loading" slot="trigger" size="small" type="primary">{{ loading ? '文件上传中' : ruleForm.apk_url ? '重新上传' : '点击上传' }}</el-button>
            <div slot="tip">{{ ruleForm.apk_url }}</div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    
  
</edit-template>
</template>

<script>
import moment from 'moment'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      nameArr: [],
      ruleForm: {
        name: '',
        code: '',
        edition: '',
        android: '',
        force: '',
        release_at: '',
        content: '',
        remarks: '',
        app_sha1: '',
        apk_url: '',
        type:null
      },
      rules: {
        name: [{ required: true, message: '请选择产品名称', trigger: 'change' }],
        code: [{ required: true, message: '请输入版本号', trigger: 'change' }],
        app_sha1: [{ required: true, message: '请输入sha1校验码', trigger: 'change' }],
        edition: [{ required: true, message: '请输入内部版本号', trigger: 'change' }],
        force: [{ required: true, message: '请选择是否强制升级', trigger: 'change' }],
        release_at: [{ required: true, message: '请选择发布时间', trigger: 'change' }],
        content: [{ required: true, message: '请输入更新内容', trigger: 'change' }],
        apk_url: [{ required: true, message: '请上传apk', trigger: 'change' }],
        type: [{ required: true, message: '请选择是否热更新', trigger: 'change' }],
      },
      action: process.env.VUE_APP_URL_BASE + '/api2/api/site/upload-version',
      loading: false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.release_at = moment(this.ruleForm.release_at).format('yyyy-MM-DD HH:mm:ss')
          this.$_axios2.post('api/evaluation/version', { ...this.ruleForm }).then(res => {
            if (res.data.status === 0) {
              this.$message.success('新增成功')
              this.$router.back()
            } else this.$message.error('新增失败')
          })
        }
      })
    },
    cancel() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '点错了',
        type: 'warning'
      })
        .then(() => {
          this.$router.back()
        })
        .catch(() => {})
    },
    onBlur() {
      const http = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
      const objExp = new RegExp(http)
      if (!objExp.test(this.ruleForm.android)) {
        this.$message.error('请输入正确的下载地址')
        this.ruleForm.android = ''
      }
    },
    uploadSectionFile(data) {
      if (!this.ruleForm.name) {
        return this.$message({ message: '请先选择产品名称!', type: 'warning' })
      }
      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('upload', data.file)
      // 在请求时添加特定属性
      form.append('version_name', this.ruleForm.name)
      this.loading = true
      this.imageUpload(form)
        .then(res => {
          const {
            data: { status, data, msg }
          } = res
          //自行处理各种情况
          if (!status) {
            this.ruleForm.apk_url = data.file_addr
            this.ruleForm.android = data.android
          }
          this.$message[status ? 'error' : 'success'](msg)
        })
        .finally(() => {
          this.loading = false
        })
    },
    imageUpload(params) {
      return this.$_axios2.post(this.action, params, {
        headers: { 'content-type': 'multipart/form-data', timeout: 1000000 }
      })
    }
  },
  created() {
    this.$_axios2.get('api/evaluation/version/get-name').then(res => {
      this.nameArr = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped></style>
